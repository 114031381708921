
    <template>
      <section class="content element-doc">
        <h2>Divider 分割线</h2>
<p>区隔内容的分割线。</p>
<h3>基础用法</h3>
<p>对不同章节的文本段落进行分割。</p>
<demo-block>
        
        <template #source><element-demo0 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;div&gt;
  &lt;span&gt;青春是一个短暂的美梦, 当你醒来时, 它早已消失无踪&lt;/span&gt;
  &lt;el-divider&gt;&lt;/el-divider&gt;
  &lt;span&gt;少量的邪恶足以抵消全部高贵的品质, 害得人声名狼藉&lt;/span&gt;
&lt;/div&gt;
</code></pre></template></demo-block><h3>设置文案</h3>
<p>可以在分割线上自定义文案内容。</p>
<demo-block>
        
        <template #source><element-demo1 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;div&gt;
  &lt;span&gt;头上一片晴天，心中一个想念&lt;/span&gt;
  &lt;el-divider content-position=&quot;left&quot;&gt;少年包青天&lt;/el-divider&gt;
  &lt;span&gt;饿了别叫妈, 叫饿了么&lt;/span&gt;
  &lt;el-divider&gt;&lt;i class=&quot;el-icon-mobile-phone&quot;&gt;&lt;/i&gt;&lt;/el-divider&gt;
  &lt;span&gt;为了无法计算的价值&lt;/span&gt;
  &lt;el-divider content-position=&quot;right&quot;&gt;阿里云&lt;/el-divider&gt;
&lt;/div&gt;
</code></pre></template></demo-block><h3>垂直分割</h3>
<demo-block>
        
        <template #source><element-demo2 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;div&gt;
  &lt;span&gt;雨纷纷&lt;/span&gt;
  &lt;el-divider direction=&quot;vertical&quot;&gt;&lt;/el-divider&gt;
  &lt;span&gt;旧故里&lt;/span&gt;
  &lt;el-divider direction=&quot;vertical&quot;&gt;&lt;/el-divider&gt;
  &lt;span&gt;草木深&lt;/span&gt;
&lt;/div&gt;
</code></pre></template></demo-block><h3>Divider Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>direction</td>
<td>设置分割线方向</td>
<td>string</td>
<td>horizontal / vertical</td>
<td>horizontal</td>
</tr>
<tr>
<td>content-position</td>
<td>设置分割线文案的位置</td>
<td>string</td>
<td>left / right / center</td>
<td>center</td>
</tr>
</tbody>
</table>

      </section>
    </template>
    <script>
      import hljs from 'highlight.js'
      import * as Vue from "vue"
      export default {
        name: 'component-doc',
        components: {
          "element-demo0": (function() {
    
    const { createVNode: _createVNode, resolveComponent: _resolveComponent, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_divider = _resolveComponent("el-divider")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode("span", null, "青春是一个短暂的美梦, 当你醒来时, 它早已消失无踪"),
    _createVNode(_component_el_divider),
    _createVNode("span", null, "少量的邪恶足以抵消全部高贵的品质, 害得人声名狼藉")
  ]))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo1": (function() {
    
    const { createVNode: _createVNode, createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_divider = _resolveComponent("el-divider")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode("span", null, "头上一片晴天，心中一个想念"),
    _createVNode(_component_el_divider, { "content-position": "left" }, {
      default: _withCtx(() => [
        _createTextVNode("少年包青天")
      ]),
      _: 1
    }),
    _createVNode("span", null, "饿了别叫妈, 叫饿了么"),
    _createVNode(_component_el_divider, null, {
      default: _withCtx(() => [
        _createVNode("i", { class: "el-icon-mobile-phone" })
      ]),
      _: 1
    }),
    _createVNode("span", null, "为了无法计算的价值"),
    _createVNode(_component_el_divider, { "content-position": "right" }, {
      default: _withCtx(() => [
        _createTextVNode("阿里云")
      ]),
      _: 1
    })
  ]))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo2": (function() {
    
    const { createVNode: _createVNode, resolveComponent: _resolveComponent, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_divider = _resolveComponent("el-divider")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode("span", null, "雨纷纷"),
    _createVNode(_component_el_divider, { direction: "vertical" }),
    _createVNode("span", null, "旧故里"),
    _createVNode(_component_el_divider, { direction: "vertical" }),
    _createVNode("span", null, "草木深")
  ]))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),
        }
      }
    </script>
  